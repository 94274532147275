<template>
  <div
    id="knowledge-base-article"
  >
    <b-row>

      <!-- related question -->
      <b-col
        lg="3"
        md="5"
        order="2"
        order-md="1"
      >
        <b-card>
          <h6 class="kb-title">
            <feather-icon
              icon="InfoIcon"
              size="20"
              class="mr-50"
            />
            Powiązane pytania
          </h6>
          <b-list-group class="list-group-circle mt-1">
            <b-list-group-item
              v-for="(que,index) in relatedQuestions"
              :key="index"
              :to="{ name: 'pages-knowledge-base-question', params: { category: $route.params.category, slug: que.slug } }"
              class="text-body"
            >
              {{ que.title }}
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <!--/ related question -->

      <b-col
        lg="9"
        md="7"
        order="1"
        order-md="2"
      >
        <b-card>

          <!-- Title -->
          <b-card-title class="mb-1">
            <feather-icon
              :icon="kb_question.icon?kb_question.icon:'InfoIcon'"
              size="21"
            />

            {{ kb_question.title }}
          </b-card-title>
          <b-card-text class="mb-2">
            Ostatnia aktualizacja: {{ kb_question.lastUpdated }}
          </b-card-text>

          <!-- Content -->
          <!-- eslint-disable vue/no-v-html -->
          <component :is="customComponent" v-if="kb_question.custom"></component>

          <div
          v-else
            class="question-content"
            v-html="kb_question.content"
          />
          <!-- eslint-enable -->
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BCardText, BCardTitle,
} from 'bootstrap-vue'
import questions from '../../const/questions.json'
import { defineAsyncComponent } from 'vue-demi'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BCardText,
    BCardTitle,
    BListGroupItem,
  },
  data() {
    return {
      kb_question: {},
      relatedQuestions: [],
    }
  },
  computed:{
    customComponent(){
      return defineAsyncComponent(()=>import(`../Knowledge-base/Custom/${this.kb_question.custom}.vue`))
    }
  },
  created() {
    this.category = questions.filter(x => x.slug == this.$route.params.category)[0]
    this.relatedQuestions = this.category.questions
    this.kb_question = this.relatedQuestions.filter(y => y.slug == this.$route.params.slug)[0]
    this.$route.meta.breadcrumb[0] = { text: this.category.title, active: true }
    this.$route.meta.breadcrumb[1] = { text: this.kb_question.title, active: true }
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.$route.meta.breadcrumb[0] = { text: this.category.title, active: true }

        this.$route.meta.breadcrumb[1] = { text: this.kb_question.title, active: true }
        this.kb_question = this.relatedQuestions.filter(y => y.slug == this.$route.params.slug)[0]
      },
    )
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
